.tik4-live *, .tik4-live *::before, .tik4-live *::after,
.tik4-modal *, .tik4-modal *::before, .tik4-modal *::after {
  box-sizing: border-box;
}

.tik4-live img,
.tik4-modal img {
  max-width: 100%;
  display: block;
}
