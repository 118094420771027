.tik4-hss {
  /* overlap */
  --t4-hss-ol: 2em;
  /* item offset */
  --t4-hss-os: 2em;
  --t4-hss-i-sp: var(--t4-sp-1);
  margin-bottom: var(--t4-sp-8);
}

.tik4-hss__label {
  font-weight: var(--t4-fw-b);
  /* font-size: var(--t4-txt-lg); */
  /* padding: 0 var(--t4-sp-4); */
}

.tik4-hss__sl {
  margin-top: var(--t4-sp-2);
  overflow: hidden;
}

.tik4-hss__sl .slick-prev {
  left: calc(-1 * var(--t4-sp-1));
}

.tik4-hss__sl .slick-arrow.slick-disabled {
  display: none;
}

.tik4-hss__sl .slick-list.slick-list {
  margin-right: var(--t4-hss-ol);
  overflow: visible;
  background: transparent;
  border: 0;
  border-radius: 0;
}
.tik4-hss__sl--last-slides .slick-list.slick-list {
  margin-right: 0;
}

.tik4-hss__sl .slick-track {
  margin-left: calc(-1 * var(--t4-hss-i-sp));
  margin-right: calc(-1 * var(--t4-hss-i-sp));
}

.tik4-hss__sl .slick-slide.slick-slide {
  display: flex;
  align-items: flex-start;
  margin: 0 var(--t4-hss-i-sp);
}
.tik4-hss__i {
  margin-right: calc(-1* var(--t4-hss-os));
}

.tik4-hss__sl .slick-slide.slick-active:not(:first-child) .tik4-hss__i {
  margin-left: var(--t4-hss-os);
}

.tik4-hss__sl .slick-slide.slick-active .tik4-hss__i {
  margin-right: 0;
}

.tik4-hss__sl .slick-slide.slick-active ~ .slick-active .tik4-hss__i {
  margin-left: 0;
  margin-right: 0;
}

.tik4-hss__sl .slick-slide.slick-slide:last-child .tik4-hss__i {
  margin-right: calc(-1 * var(--t4-hss-ol));
}

.tik4-hss__sl .slick-slide > div {
  width: 100%;
  height: 100%;
}

/* trying to get equal widths for elements */

.tik4-hss__sl .slick-slide {
  --t4-hss-teil: calc(var(--t4-hss-os) / var(--t4-hss-sts));
}

.tik4-hss__sl .slick-slide.slick-active:not(:first-child) .tik4-hss__i {
  --t4-hss-sla-ml: calc(var(--t4-hss-os) - var(--t4-hss-teil) * var(--t4-hss-sla-i));
  margin-left: var(--t4-hss-sla-ml);
  margin-right: calc(var(--t4-hss-os) * (-1 + (var(--t4-hss-sla-i) + 1) / var(--t4-hss-sts)));
}

/* reset everything if not slided */
.tik4-hss__sl .slick-slide.slick-active:first-child ~ .slick-active .tik4-hss__i {
  /* background: orange !important; */
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* first active element */
.tik4-hss__sl .slick-slide.slick-active:not(:first-child) .tik4-hss__i {
  /* background: purple; */
  --t4-hss-sla-i: 0;
  /* margin-left: var(--t4-hss-os); */
  /* margin-right: calc(var(--t4-hss-os) * (-1 + 1 / var(--t4-hss-sts))); */
}

/* 2nd (second after first active element */
.tik4-hss__sl .slick-slide.slick-active:not(:first-child) ~ .slick-active .tik4-hss__i {
  /* background: blue; */
  --t4-hss-sla-i: 1;
  /* margin-left: calc(var(--t4-hss-os) - var(--t4-hss-teil)); */
  /* margin-right: calc(var(--t4-hss-os) * (-1 + 2 / var(--t4-hss-sts))); */
}
/* 3rd (third element after active) */
.tik4-hss__sl .slick-slide.slick-active:not(:first-child) ~ .slick-active ~ .slick-active .tik4-hss__i {
  /* background: green; */
  --t4-hss-sla-i: 2;
  /* margin-left: calc(var(--t4-hss-os) - var(--t4-hss-teil) * 2); */
  /* margin-right: calc(var(--t4-hss-os) * (-1 + 3 / var(--t4-hss-sts))); */
}
/* 4th (third fourth after active) */
.tik4-hss__sl .slick-slide.slick-active:not(:first-child) ~ .slick-active ~ .slick-active ~ .slick-active .tik4-hss__i {
  /* background: pink; */
  --t4-hss-sla-i: 3;
  /* margin-left: calc(var(--t4-hss-os) - var(--t4-hss-teil) * 3); */
  /* margin-right: calc(var(--t4-hss-os) * (-1 + 4 / var(--t4-hss-sts))); */
}
/* 4th (third fourth after active) */
.tik4-hss__sl .slick-slide.slick-active:not(:first-child) ~ .slick-active ~ .slick-active ~ .slick-active ~ .slick-active .tik4-hss__i {
  /* background: yellow; */
  --t4-hss-sla-i: 4;
  /* margin-left: calc(var(--t4-hss-os) - var(--t4-hss-teil) * 4); */
  /* margin-right: calc(var(--t4-hss-os) * (-1 + 5 / var(--t4-hss-sts))); */
}
/* end of equal widhts */

/* item (i) */
.tik4-hss__i {
  background: var(--t4-ev-h-bg);
  padding: var(--t4-sp-3) var(--t4-sp-4);
  box-sizing: border-box;
  height: 100%;
  position: relative;
  &[role="button"] {
    &:hover {
      cursor: pointer;
    }
  }
}

/* .tik4-hss__sl .slick-slide:nth-child(odd) .tik4-hss__i {
  background: red;
} */


/* item header (i__h) */
.tik4-hss__i__h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--t4-sp-10);
}

/* datetime */

.tik4-hss__dt {
  display: flex;
  /* align-items: center; */
  min-width: 0;
  padding-right: .5em;
  flex-basis: 70%;
  max-width: min-content;

  flex-direction: column;
  line-height: 1.3;
}

.tik4-hss__dt__d {
  white-space: nowrap;
  color: var(--t4-muted);
  overflow-x: hidden;
  overflow-x: clip;
  text-overflow: ellipsis;
  font-size: var(--t4-txt-sm);
}

.tik4-hss__dt__t {
  white-space: nowrap;
  font-weight: var(--t4-fw-b);
}


/* author */
.tik4-hss__a {
  min-width: 0;
  flex: 1 1 0;
  max-width: max-content;
}

.tik4-hss__a__w {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tik4-hss__a__t {
  border-radius: 9999em;
  margin-left: var(--t4-sp-2);
  overflow: hidden;
  height: 2em;
  width: 2em;
  flex: 0 0 2em;
}

.tik4-hss__a__t__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.tik4-hss__a__n {
  font-size: var(--t4-txt-sm);
  color: var(--t4-muted);
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  /* white-space: nowrap; */
  line-height: 1.1;

  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}



.tik4-live--author-off .tik4-hss__a {
  display: none;
}


.tik4-hss__c {
  @extend %tik4-break-safe;
  margin-top: var(--t4-sp-2);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: calc(1em * var(--t4-txt-lh) * 3);
}
